import React from 'react';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

class TagsPage extends React.Component<TagsPageType> {
  render() {
    const group = this.props.data.allMarkdownRemark.group;
    const title = this.props.data.site.siteMetadata.title;

    return (
      <Layout>
        <Container>
          <Helmet title={`Tags | ${title}`} />
          <h1><i className="fas fa-tags"></i>&nbsp;Tags</h1>
          {group.map(tag => (
            <Card key={tag.fieldValue} className="tags-card">
              <Card.Body>
                <Card.Title>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </Card.Title>
                <Card.Text>{tag.totalCount} {tag.totalCount === 1 ? 'Blog-Beitrag' : 'Blog-Beiträge'}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Layout>
    );
  };
};

type TagsPageType = {
  data: {
    allMarkdownRemark: {
      group: [{
        fieldValue: string,
        totalCount: number,
      }],
    },
    site: {
      siteMetadata: {
        title: string,
      },
    },
  },
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
